<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner auth-inner_welcome py-2">
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{ name: 'login' }"
        >
          <logo />
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('emailConfirmationTitle') }}
        </b-card-title>

        <div class="mb-1">
          <b-spinner variant="primary" />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BLink,
  BSpinner,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import CONFIRM_USER_EMAIL_CHANGE from '@/gql/mutation/user/confirmUserEmailChange.gql'

export default {
  components: {
    BCard,
    BCardTitle,
    BLink,
    BSpinner,
    Logo,
  },
  data() {
    return {
      appName: $themeConfig.app.appName,
    }
  },
  async created() {
    const { confirmationCode } = this.$route.query
    if (confirmationCode) {
      try {
        await this.$apollo.mutate({
          mutation: CONFIRM_USER_EMAIL_CHANGE,
          variables: {
            confirmationCode,
          },
        })

        this.clearLocalStorageData()

        // Reset ability
        // this.$ability.update(initialAbility)

        // Redirect to login page
        await this.$router.push({ name: 'login' })

        this.clearStoreData()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessEmailConfirmed'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        await this.$router.push({ name: 'dashboard' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    } else {
      await this.$router.push({ name: 'dashboard' })

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Error'),
          text: this.$t('notificationErrorMissingConfirmationCode'),
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    }
  },
  methods: {
    ...mapActions({
      clearLocalStorageData: 'main/clearLocalStorageData',
      clearStoreData: 'main/clearStoreData',
    }),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
